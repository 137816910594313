<template>
  <div class="vhelp-error">
    <div class="vhelp-error-content">
      <img src="@/assets/img/svgs/blank_canvas.svg" class="img-error"/><br>
      <div class="text-center" style="margin-top: 15px">
        <h4>401 - Unauthorized</h4>
        <router-link :to="{name: 'home'}" class="btn btn-link">Go to Homepage</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Unauthorized',
  metaInfo: {
    title: 'Unauthorized',
    titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Unauthorized' }
    ]
  }
}
</script>

<style scoped>
.vhelp-error {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.vhelp-error-content {
  display: block;
}

.img-error {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>
